// Set the cookie
function useSetCookie(c_name: string, value: string, expire: number) {
	var exdate = new Date(Date.now() + expire * 86400000); // expire unit = 1 day
	document.cookie = c_name + '=' + escape(value) + (expire == null ? '' : ';expires=' + exdate.toUTCString()) + ';path=/';
}

// Get the cookie content
function useGetCookie(c_name: string) {
	if (document.cookie.length > 0) {
		let c_start = document.cookie.indexOf(c_name + '=');
		if (c_start != -1) {
			c_start = c_start + c_name.length + 1;
			let c_end = document.cookie.indexOf(';', c_start);
			if (c_end == -1) {
				c_end = document.cookie.length;
			}
			return unescape(document.cookie.substring(c_start, c_end));
		}
	}
	return '';
}

export default { useSetCookie, useGetCookie };
