<template>
	<!-- cookie -->
	<div :class="cookieClass">
		<!-- cookie notice -->
		<div v-if="isCookieNoticeOpen" class="cookie">
			<div class="title-container">
				<h1>About Cookies</h1>
			</div>

			<p>
				This website uses cookies for the display of the website and its features. Please see more information about cookies used in
				our
				<nuxt-link target="_blank" to="/cookies">Cookie Policy</nuxt-link>.
			</p>
			<div class="options-wrapper">
				<button class="bordered-btn" @click="saveSettings(true)">OK</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import storageAvailable from 'storage-available';

// Constants
const COOKIE_NAME_ENABLED = 'cookielaw:enable';
const COOKIE_NAME_DISABLED = 'cookielaw:disable';
const IS_LOCAL_STORAGE_AVAILABLE = storageAvailable('localStorage');

// Refs/reactives
const isCookieNoticeOpen = ref(false);
const isManageCookieWindowOpen = ref(false);

// Computed
const cookieClass = computed(() => {
	return {
		'cookie-settings': true,
		'modal-open': isManageCookieWindowOpen.value
	};
});

// Lifecycle hooks
onMounted(() => {
	if (!areCookiesAccepted()) {
		isCookieNoticeOpen.value = true;
	}
});

function closeAllWindows() {
	isCookieNoticeOpen.value = false;
	isManageCookieWindowOpen.value = false;
}

function saveSettings(value: boolean) {
	const name = value ? COOKIE_NAME_ENABLED : COOKIE_NAME_DISABLED;

	if (IS_LOCAL_STORAGE_AVAILABLE) localStorage.setItem(name, 'true');
	else cookies.useSetCookie(name, 'true', 10000);

	closeAllWindows();
}

function areCookiesAccepted() {
	if (IS_LOCAL_STORAGE_AVAILABLE && (localStorage.getItem(COOKIE_NAME_ENABLED) || localStorage.getItem(COOKIE_NAME_DISABLED)))
		return localStorage.getItem(COOKIE_NAME_ENABLED) === 'true';
	else if (!IS_LOCAL_STORAGE_AVAILABLE && (cookies.useGetCookie(COOKIE_NAME_ENABLED) || cookies.useGetCookie(COOKIE_NAME_DISABLED)))
		return cookies.useGetCookie(COOKIE_NAME_ENABLED) === 'true';
	else return undefined;
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

.cookie {
	overflow: hidden;
	position: fixed;
	-webkit-box-sizing: border-box;
	z-index: 10012;
	bottom: 10px;
	left: 0;
	right: 0;
	padding: 0 20px 20px;
	margin: 0 auto;
	width: 100%;
	max-width: 620px;
	color: #cccccc;
	// background: linear-gradient(180deg, rgba(21, 21, 21, 0.24) 0%, #151515 100%);
	background: #151515;
	font-size: 14px;
	box-sizing: border-box;
	border-radius: 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	border: 1px solid #9c9b9b;

	.title-container {
		margin: 10px 0;
	}

	h1 {
		font-style: normal;
		font-weight: 700;
		text-align: left;
		font-size: 18px;
		line-height: 18px;
		color: #ffffff;
		margin-bottom: 20px;
	}

	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #cccccc;
		margin-top: 20px;
		margin-bottom: 16px;
		a {
			cursor: pointer;
			color: #ff003c;
		}
	}

	.options-wrapper {
		display: flex;
		gap: 20px;
		button {
			padding: 8px 20px;
			&.noborder-btn {
				background: transparent;
				border: 1px solid #cccccc;
				box-sizing: border-box;
				border-radius: 4px;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #cccccc;
			}
			&.bordered-btn {
				left: 0px;
				top: 0px;
				font-size: 12px;
				line-height: 16px;
				background: #ff003c;
				border-radius: 4px;
				border: 1px solid #ff003c;
				color: #ffffff;
			}
			@media screen and (max-width: 480px) {
				padding: 5px 9px;
			}
		}
		.text-btn {
			background: transparent;
			border-radius: 4px;
			border: none;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
			padding: 0;
			color: #ff0050;
			margin-left: auto;
			@media screen and (max-width: 480px) {
				font-size: 14px;
				line-height: 19px;
			}
		}
		@media screen and (max-width: 480px) {
			gap: 10px;
		}
	}
}

.modal-open {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;

	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10013;
	outline: 0;
}

// manage cookies
.manage-cookies {
	margin-bottom: 10px;
	.cookie-modal {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		max-width: 620px;
		pointer-events: auto;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
		background: #151515;
		border: 1px solid rgba(255, 255, 255, 0.2);
		box-sizing: border-box;
		backdrop-filter: blur(40px);
		border-radius: 8px;
		margin: 0 auto;
		a {
			color: #007bff;
		}
		.manage-header {
			border-bottom: 1px solid #a1a9af;
			padding: 1rem;
			.header-logo {
				display: block;
				position: relative;
				// width: 111px;
				height: 28px;
				margin: 0 auto;
			}
		}
		.manage-content {
			padding: 20px;
			p {
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: #cccccc;
				margin-bottom: 16px;
				margin-top: 26px;
				a {
					cursor: pointer;
					color: #ff003c;
				}
			}
			h1 {
				font-style: normal;
				font-weight: 700;
				text-align: left;
				font-size: 18px;
				line-height: 18px;
				color: #ffffff;
				margin-bottom: 16px;
			}
			.cookie-list {
				padding: 11px 12px;
				background: rgba(128, 128, 128, 0.2);
				margin-bottom: 3px;
				.toggle-wrapper {
					display: flex;
				}
				button {
					background: transparent;
					border: 0;
					color: #333;
					cursor: pointer;
					font-size: 14px;
					padding: 0;
					position: relative;
					text-decoration: none;
					text-transform: uppercase;
				}
				.title-wrapper {
					display: flex;
					@media screen and (min-width: 520px) {
						flex-direction: row;
						justify-content: space-between;
					}
					p {
						margin-left: auto;
						display: flex;
						/* justify-content: center; */
						align-items: center;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						line-height: 22px;
						color: #cccccc;
						margin-bottom: 0px;
					}
				}
				h2 {
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 0;
					color: #ffffff;
				}
				.cookies-more {
					display: flex;
					justify-content: center;
					margin-left: auto;
					.single-cookie {
						position: relative;
						display: flex;
						p {
							// center cookie title vertically
							line-height: 50px;
							margin-left: 15px;
						}
						// stylings for opt out options - when toggle button available
						&.opt-out {
							display: flex;
							align-items: center;
							justify-content: space-between;
							p {
								margin-bottom: 0px;
								margin-left: 0px;
							}
							.checkbox-wrapper {
								// height: 34px;
								.toggle-wrapper {
									width: 42px;
									min-width: 30px;
									height: 18px;
									border-radius: 9px;
									transition: all 0.15s ease-out;
									cursor: pointer;
									.toggler {
										width: 18px;
										height: 18px;
										border-radius: 50%;
										background-color: #f2f2f3;
										transition: all 0.15s ease-out;
									}
									// define toggle states and animation effect
									&.--enabled {
										background-color: #ff003c;
										.toggler {
											margin-left: 23px;
										}
									}
									&.--disabled {
										background-color: #d6d8dd;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.options-wrapper {
		display: flex;
		gap: 20px;
		margin-top: 12px;
		button {
			padding: 8px 20px;
			&.noborder-btn {
				background: transparent;
				border: 1px solid #cccccc;
				box-sizing: border-box;
				border-radius: 4px;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #cccccc;
			}
			&.bordered-btn {
				left: 0px;
				top: 0px;
				font-size: 12px;
				line-height: 16px;
				background: #ff003c;
				border-radius: 4px;
				border: 1px solid #ff003c;
				color: #ffffff;
			}
			@media screen and (max-width: 480px) {
				padding: 5px 9px;
				&.text-btn {
					font-size: 14px;
					line-height: 19px;
				}
			}
		}
		.text-btn {
			background: transparent;
			border-radius: 4px;
			border: none;
			padding: 0;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 22px;
			color: #ff0050;
			margin-left: auto;
		}
	}
	@media screen and (max-width: 620px) {
		margin-bottom: 0;
		.cookie-modal {
			border: none !important;
			border-radius: 0;
			& .manage-content {
				& .cookie-list {
					h2 {
						font-size: 14px;
						line-height: 19px;
					}
					& .title-wrapper p {
						font-size: 12px;
						line-height: 16px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 320px) {
		.cookie-modal {
			& .manage-content {
				& .cookie-list {
					h2 {
						font-size: 13px;
						line-height: 17px;
					}
				}
			}
		}
	}
}
</style>
